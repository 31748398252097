import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import { ApiUrls, AuthProviderService, ConfigDataService, } from "../../../core/services";
import { IAppConfig, IAuthUser, IReviewAttachment } from "../../../models";
import { DocUploadStatus } from "../../../models/attachment";
import { AttachmentType } from "../../../models/enums/app.enum";

import Swal from "sweetalert2";

@Component({
  selector: "app-view-attachment",
  templateUrl: "./view-attachment.component.html",
})
export class ViewAttachmentComponent implements OnInit, OnChanges {
  url: string = "";
  _config: IAppConfig;
  claimDocuments: IReviewAttachment[];
  invoices: IReviewAttachment[];
  vendorDocs: IReviewAttachment[];
  supportings: IReviewAttachment[];
  torDocs: IReviewAttachment[];

  user: IAuthUser;
  DocUploadedStatus: DocUploadStatus;

  @Input() reviewAttachments: IReviewAttachment[];

  constructor(
    private readonly http: HttpClient,
    private readonly config: ConfigDataService,
    private readonly authServ: AuthProviderService,
    private translate: TranslateService
  ) {
    this._config = this.config.getConfig();
    this.url = `${this._config.apiUrl.baseApiUrlDocMgnt}/${ApiUrls.DownloadDoc}?fileNames=`;
  }

  ngOnChanges() {
    // attachments
    let len = this.reviewAttachments?.length;
    if (len > 0) {
      this.invoices = [];
      this.claimDocuments = [];
      this.vendorDocs = [];
      this.supportings = [];
      this.torDocs = [];
      this.extractDoc(this.reviewAttachments);
    } else {
      this.invoices = [];
      this.claimDocuments = [];
      this.vendorDocs = [];
      this.supportings = [];
      this.torDocs = [];
    }
  }

  private extractDoc(e: Array<IReviewAttachment>) {
    for (let i in e) {
      const element = e[i];
      const docType = element.documentType.toLocaleUpperCase();
      // fixing upload status
      if (  element.docStatus === 'Success' || element.docStatus === 'Successfull' ) {
        element.docStatus = DocUploadStatus.Uploaded;
      }
      // push attachments to arrays
      if (docType === AttachmentType.Claim) {
        this.claimDocuments.push(element);
      } else if (docType === AttachmentType.Invoice) {
        this.invoices.push(element);
      } else if (docType === AttachmentType.Vendor) {
        this.vendorDocs.push(element);
      } else if (docType === AttachmentType.Supporting) {
        this.supportings.push(element);
      } else if (docType === AttachmentType.TOR) {
        this.torDocs.push(element);
      }
    }
    // order arrays
    this.claimDocuments.sort((a, b) => a.docDisplayName.localeCompare(b.docDisplayName));
    this.invoices.sort((a, b) => a.docDisplayName.localeCompare(b.docDisplayName));
    this.vendorDocs.sort((a, b) => a.docDisplayName.localeCompare(b.docDisplayName));
    this.supportings.sort((a, b) => a.docDisplayName.localeCompare(b.docDisplayName));
    this.torDocs.sort((a, b) => a.docDisplayName.localeCompare(b.docDisplayName));
  }

  ngOnInit() {
    this.authServ.getAuthUser().subscribe((u) => {
      this.user = u;
    });
  }

  // download/view file
  viewFile(f: IReviewAttachment) {
    if (f.documentPath) {
      if ( ( f.docStatus === DocUploadStatus.Uploaded && f.documentType == AttachmentType.Claim ) || f.documentType != AttachmentType.Claim ) {
        this.http.get( `${this._config.apiUrl.baseApiUrlDocMgnt}/${ApiUrls.DownloadDoc}?fileNames=${encodeURIComponent(f.documentName)}&blobContainer=${f.documentPath}`, { responseType: "blob" }).subscribe((result) => {
          let blob = new Blob([result]);
          saveAs(blob, f.docDisplayName);
        });
      } else if (f.docStatus === DocUploadStatus.Pending) {
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('common.warning'),
          text: this.translate.instant('warningnMessages.claimAttachmentWaitDownload'),
          confirmButtonText: this.translate.instant('common.done'),
        })
      } else if (f.docStatus === DocUploadStatus.Failed) {
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('common.warning'),
          text: this.translate.instant('warningnMessages.claimAttchUploadFailed'),
          confirmButtonText: this.translate.instant('common.done'),
        })
      } else {
        return;
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('common.warning'),
        text: this.translate.instant('assignment.attachmentWaitDownload'),
        confirmButtonText: this.translate.instant('common.done'),
      })
    }
  }

  viewTORFile(f: IReviewAttachment) {
    if (f.documentPath) {
      if ( (f.docStatus === DocUploadStatus.Uploaded && f.documentType == AttachmentType.Claim) || f.documentType != AttachmentType.Claim ) {
        this.http.get(`${this._config.apiUrl.baseApiUrlDocMgnt}/${ApiUrls.DownloadDoc}?fileNames=${encodeURIComponent(f.documentName.substring((f.documentName.indexOf('_')+1), (f.documentName.length)))}&blobContainer=${f.documentPath}`, { responseType: "blob" } ).subscribe((result) => {
          let blob = new Blob([result]);
          saveAs(blob, f.docDisplayName);
        });
      } else if (f.docStatus === DocUploadStatus.Pending) {
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('common.warning'),
          text: this.translate.instant('warningnMessages.claimAttachmentWaitDownload'),
          confirmButtonText: this.translate.instant('common.done'),
        })
      } else if (f.docStatus === DocUploadStatus.Failed) {
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('common.warning'),
          text: this.translate.instant('warningnMessages.claimAttchUploadFailed'),
          confirmButtonText: this.translate.instant('common.done'),
        })
      } else {
        return;
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('common.warning'),
        text: this.translate.instant('warningnMessages.attachmentWaitDownload'),
        confirmButtonText: this.translate.instant('common.done'),
      })
    }
  }

  setVisibility(ctrl: string) {
    return this.authServ.setControlVisibility(this.user, ctrl);
  }
}
