export const environmentUat = {
    apiUrl: {
      baseApiUrl: "https://emeauat.chubbdigital.com/claims.vendormanagement",
      baseApiUrlAssignment: "https://emeauat.chubbdigital.com/claims.vendorassignment",
      baseApiUrlCommonRef: "https://nauat.chubbdigital.com/claims.commonreference",
      baseApiUrlAppCommonRef: "https://emeauat.chubbdigital.com/cog.claims.cogcommonreference",
      baseApiUrlDocMgnt: "https://emeauat.chubbdigital.com/claims.cogdocumentmanagement",
      baseApiUrlVendorAdb2c: "https://nauat.chubbdigital.com",
      newVendorRegistrationURL: "https://vendorassignment-uat.chubb.com/vendorlogin/registration/organization-name?ui_locales={{**}}"
    },
    authProviderConfig: {
      authProvider: "MSAL2WithEvents",
      tenant: "auth.uat.chubb.com",
      clientId: "bd440017-c4cc-4e4a-9238-cd3ae4123d3d",
      policy: "B2C_1A_COGVENDOR_SIGNIN",
      redirectUrl: "https://vendorassignment-uat.chubb.com",
      scopes: [
        {
          scope: "https://uatb2c.chubbdigital.com/COGVendorAssignmentAPI/write"
        },
        {
          scope: "https://uatb2c.chubbdigital.com/COGVendorAssignmentAPI/read"
        }
      ],
      queryParameters: [
        {
          key: "domain_hint",
          value: "CHUBBgroup"
        }
      ]
    },
    jwt: {
      common: {
        url: "https://emeauat.chubbdigital.com/enterprise.operations.authorization?Identity=AAD",
        apP_ID: "58e3739b-8e43-45e4-8470-c0470c353d63",
        apP_KEY: "4P_.m6H7uW5~KP-p94kqOnuSqX-.c2kS~m",
        resource: "ffaa933e-38a3-48d4-b0f2-ed4a75234829",
        ocpApimSubscriptionKey: "2e47dbd8ea444916848cf838e15e116c",
        apiVersion: "1"
      },
      cogcommon: {
        url: "https://emeauat.chubbdigital.com/enterprise.operations.authorization?Identity=AAD",
        apP_ID: "b3dbe3c3-841a-46af-94a5-6d98929a90a2",
        apP_KEY: "wE-5_WgwGYV-B41My.81dhuso9_RcBdU1u",
        resource: "69175256-9be9-4399-88d3-218b41951263",
        ocpApimSubscriptionKey: "dafb3cba23cc44d58bc0f2657648183d",
        apiVersion: "1"
      },
      cogvendormanager: {
        url: "https://emeauat.chubbdigital.com/enterprise.operations.authorization?Identity=AAD",
        apP_ID: "e9673cf4-30f0-42ba-abbc-ac0392f05a80",
        apP_KEY: ".KejwG.G2-67r--4_4~HiC26SdnD3d9X0H",
        resource: "3e2d82ec-2082-45de-bcd5-800f4e33ceb9",
        ocpApimSubscriptionKey: "01bc43b4c6c548bab50e9daead39b6aa",
        apiVersion: "1"
      },
      cogvendorassignment: {
        url: "https://emeauat.chubbdigital.com/enterprise.operations.authorization?Identity=AAD",
        apP_ID: "5c1481f2-409f-4699-9f83-65f992d8d520",
        apP_KEY: "f~46sUbhc0ZIT8eA0VGkjh_hw4-Du~6EhO",
        resource: "82d22919-e4f6-445a-9303-f54c3f462700",
        ocpApimSubscriptionKey: "8bab0b30587b417eaadf724a7ec7b0c0",
        apiVersion: "1"
      },
      cogdocumentmanagement: {
        url: "https://emeauat.chubbdigital.com/enterprise.operations.authorization?Identity=AAD",
        apP_ID: "6a112ef7-5fb8-4852-9310-92f2f115e9a9",
        apP_KEY: "14Q42sg~40O6M~mh.97p._pvlzYS4noIvl",
        resource: "03587a20-70a7-45c4-b441-05cb1773789d",
        ocpApimSubscriptionKey: "75a6f8034efd45a8a5e13b327fbd5d1f",
        apiVersion: "1"
      },
      cogadb2cvendor: {
        url: "",
        apP_ID: "",
        apP_KEY: "",
        resource: "",
        ocpApimSubscriptionKey: "cec17954db2e406d80813185f8e610bc",
        apiVersion: ""
      },
      cogadb2adgroup: {
        url: "",
        apP_ID: "",
        apP_KEY: "",
        resource: "",
        ocpApimSubscriptionKey: "cec17954db2e406d80813185f8e610bc",
        apiVersion: ""
      }
    },
    appConstants: {
      parsingTeamEmail: "Sean.Milleman@Chubb.com"
    }
  }
  