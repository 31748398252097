import { IApiErrorResponse, IApiResponse } from "./iapi-response";

export interface IRetrieveDocument {
  shortDesc: string;
  attachmentID: string;
  fileName: string;
  extension: string;
  createdOn: Date;
}

export interface ITORDocument {
  torDocumentTemplateID: string;
  templateName: string;
  region: string;
  languageCode: string;
  effectiveDate: Date;
  expiryDate: Date;
  isActive: boolean;
}

export interface IReviewAttachment {
  documentID: string;
  documentName: string;
  documentType: string;
  documentPath: string;
  docDisplayName: string;
  docStatus: DocUploadStatus;
}

export enum DocUploadStatus {
  Failed = "Failed",
  Pending = "Pending",
  Success = "Success",
  Successfully = "Successfull",
  Uploaded = "Uploaded",
}

export interface IClaimDocuments {
  claimsDocuments: IRetrieveDocument[];
  apiError: IApiErrorResponse;
}

export interface ITORDocuments {
  torDocuments: ITORDocument[];
  apiError: IApiErrorResponse;
}

export interface IClaimDocUploadToBlobReqObj {
  documentObjects: IClaimDocObjects[];
  claimNumber: string;
  assignmentID: string;
  docCatagory: string;
  country: string;
  region: string;
  lastModifiedBy: string;
}

export interface ITORDocUploadReqObj {
  documentObjects: ITORDocObjects[];
  claimNumber: string;
  assignmentID: string;
  docCatagory: string;
  country: string;
  region: string;
  lastModifiedBy: string;
}

export interface IClaimDocObjects {
  documentID: string;
  fileName: string;
  extension: string;
}

export interface ITORDocObjects {
  documentID: string;
  fileName: string;
  extension: string;
}

export interface IClaimDocUploadToBlobResponse {
  uploadClaimsDocuments: IClaimDocUploadToBlobRespObj[];
  apiError: IApiErrorResponse;
}

export interface IClaimDocUploadToBlobRespObj {
  vendorAssignmentDocumentID: string;
  originalDocumentName: string;
  documentFileName: string;
  documentType: string;
  documentUploadMethod: string;
  documentStoreSource: string;
  documentUrlLink: string;
  documentPath: string;
  documetStatus: string;
  comments: string;
  errorMsg: string;
  region: string;
  country: string;
  claimNumber: string;
  successStatus: boolean;
  message: string;
}

export interface IRemoveDocRespObj extends IApiResponse {
  vendorAssignmentDocumentID: string;
  successStatus: boolean;
  message: string;
}
