export const environmentSit = {
  apiUrl: {
    baseApiUrl: "https://sit.emea.studiogateway.chubb.com/claims.vendormanagement",
    baseApiUrlAssignment: "https://sit.emea.studiogateway.chubb.com/claims.vendorassignment",
    baseApiUrlCommonRef: "https://sit.emea.studiogateway.chubb.com/claims.vendormanagement",
    baseApiUrlAppCommonRef: "https://sit.emea.studiogateway.chubb.com/other.claims.cogcommonreference",
    baseApiUrlDocMgnt: "https://sit.emea.studiogateway.chubb.com/claims.cogdocumentmanagement",
    baseApiUrlVendorAdb2c: "https://sit.emea.studiogateway.chubb.com",
    newVendorRegistrationURL: "https://vendorassignment-sit.chubb.com/vendorlogin/registration/organization-name?ui_locales={{**}}"
  },
  authProviderConfig: {
    authProvider: "MSAL2WithEvents",
    tenant: "auth.sit.chubb.com",
    clientId: "01877fd8-e436-4e0a-9add-5821fd8e50b7",
    policy: "B2C_1A_COGVENDOR_SIGNIN",
    redirectUrl: "https://cogclaimsvendormanageassignweb-dev.azurewebsites.net",
    scopes: [
      {
        scope: "https://cbaadb2c08sit.onmicrosoft.com/COGVendorAssignmentAPI/write"
      },
      {
        scope: "https://cbaadb2c08sit.onmicrosoft.com/COGVendorAssignmentAPI/read"
      }
    ],
    queryParameters: [
      {
        key: "domain_hint",
        value: "CHUBBgroup"
      }
    ]
  },
  jwt: {
    common: {
      url: "https://sit.emea.studiogateway.chubb.com/enterprise.operations.authorization",
      apP_ID: "06c7791e-4358-4961-9436-24b676f99d49",
      apP_KEY: "VIU8Q~yXXD0HS86fbI_RoeIMv_A7_q1GXfeGZaug",
      resource: "f2edee3d-54ed-436e-81a9-65e4dc2a16f2",
      ocpApimSubscriptionKey: "450ad256cd6a4c50af5e8b888da57a42",
      apiVersion: "1"
    },
    cogcommon: {
      url: "https://sit.emea.studiogateway.chubb.com/enterprise.operations.authorization",
      apP_ID: "06c7791e-4358-4961-9436-24b676f99d49",
      apP_KEY: "VIU8Q~yXXD0HS86fbI_RoeIMv_A7_q1GXfeGZaug",
      resource: "f2edee3d-54ed-436e-81a9-65e4dc2a16f2",
      ocpApimSubscriptionKey: "1cd53977bcfe41c495cc2464412346ec",
      apiVersion: "1"
    },
    cogvendormanager: {
      url: "https://sit.emea.studiogateway.chubb.com/enterprise.operations.authorization",
      apP_ID: "06c7791e-4358-4961-9436-24b676f99d49",
      apP_KEY: "VIU8Q~yXXD0HS86fbI_RoeIMv_A7_q1GXfeGZaug",
      resource: "f2edee3d-54ed-436e-81a9-65e4dc2a16f2",
      ocpApimSubscriptionKey: "d7ce0ae86ed248c2803af24650c52900",
      apiVersion: "1"
    },
    cogvendorassignment: {
      url: "https://sit.emea.studiogateway.chubb.com/enterprise.operations.authorization",
      apP_ID: "06c7791e-4358-4961-9436-24b676f99d49",
      apP_KEY: "VIU8Q~yXXD0HS86fbI_RoeIMv_A7_q1GXfeGZaug",
      resource: "f2edee3d-54ed-436e-81a9-65e4dc2a16f2",
      ocpApimSubscriptionKey: "0a4a7ce7f0964e6ca7a8b98fc8a82fc7",
      apiVersion: "1"
    },
    cogdocumentmanagement: {
      url: "https://sit.emea.studiogateway.chubb.com/enterprise.operations.authorization",
      apP_ID: "06c7791e-4358-4961-9436-24b676f99d49",
      apP_KEY: "VIU8Q~yXXD0HS86fbI_RoeIMv_A7_q1GXfeGZaug",
      resource: "f2edee3d-54ed-436e-81a9-65e4dc2a16f2",
      ocpApimSubscriptionKey: "904ec82054e0410ea496f5c6553285ae",
      apiVersion: "1"
    },
    cogadb2cvendor: {
      url: "",
      apP_ID: "",
      apP_KEY: "",
      resource: "",
      ocpApimSubscriptionKey: "b84690b194f547789d69910bd250649b",
      apiVersion: ""
    },
    cogadb2adgroup: {
      url: "",
      apP_ID: "",
      apP_KEY: "",
      resource: "",
      ocpApimSubscriptionKey: "b84690b194f547789d69910bd250649b",
      apiVersion: ""
    }
  },
  appConstants: {
    parsingTeamEmail: "tanuja.naik@chubb.com"
  }
}